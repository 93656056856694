import React from 'react';
import { styled } from '@mui/material';
import { BgImage } from 'gbimage-bridge';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import SectionTitle from 'components/Title';
import Flex from 'components/Flex';

const Image = styled(BgImage)({
	display: 'flex',
	flex: 1,
	flexDirection: 'column',
});

const SectionRoot = styled(Flex, { shouldForwardProp: (prop) => prop !== 'whiteBackground' })<{
	whiteBackground?: boolean;
}>(({ whiteBackground }) => ({
	background: whiteBackground ? 'white' : 'default',
}));

const ContentContainer = styled(Flex, { shouldForwardProp: (prop) => prop !== 'padding' })<Props>(
	({ theme, padding }) => ({
		padding: padding ? theme.spacing(20, 0, 10) : 0,
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: padding ? theme.spacing(15, 5, 5) : 0,
		},
		[theme.breakpoints.down('xs')]: {
			padding: padding ? theme.spacing(15, 3, 5) : 0,
		},
	}),
);

interface Props {
	padding?: boolean;
	title?: string;
	subtitle?: string;
	white?: boolean;
	children: React.ReactNode;
	background?: IGatsbyImageData;
	whiteBackground?: boolean;
	className?: string;
	noMarginTitle?: boolean;
}

export default (props: Props) => {
	const renderTitle = () => {
		if (props?.title) {
			return (
				<SectionTitle
					title={props?.title}
					subtitle={props?.subtitle}
					white={props?.white}
					noMarginBottom={props?.noMarginTitle}
				/>
			);
		}
		return null;
	};

	const renderContent = () => (
		<ContentContainer
			padding={props?.padding}
			direction="column"
			align="center"
			defaultFlex
			width
		>
			{renderTitle()}
			{props?.children}
		</ContentContainer>
	);

	if (props?.background) {
		return <Image image={props?.background}>{renderContent()}</Image>;
	}

	return (
		<SectionRoot
			direction="column"
			whiteBackground={props?.whiteBackground}
			align="center"
			className={props?.className}
			width
		>
			{renderContent()}
		</SectionRoot>
	);
};
