import React from 'react';
import { Typography, styled } from '@mui/material';

const Text = styled(Typography, { shouldForwardProp: (prop) => prop !== 'white' })<{
	white?: boolean;
}>(({ theme, white }) => ({
	color: white ? 'white' : theme.palette.primary.main,
}));

export interface BaseProps {
	white?: boolean;
}

interface TextProps extends BaseProps {
	children: string;
	variant: 'h1' | 'h2' | 'h3' | 'h4';
}

export default (props: TextProps) => (
	<Text variant={props?.variant} align="center" white={props?.white}>
		{props?.children}
	</Text>
);
