import React from 'react';
import { styled } from '@mui/material';

import Flex from 'components/Flex';
import Text, { BaseProps } from 'components/Title/Text';

const TitleContainer = styled(Flex, {
	shouldForwardProp: (prop) =>
		prop !== 'noMarginBottom' && prop !== 'small' && prop !== 'marginTop' && prop !== 'reverse',
})<{
	noMarginBottom?: boolean;
	small?: boolean;
	marginTop?: boolean;
	reverse?: boolean;
}>(({ theme, noMarginBottom, small, marginTop, reverse }) => ({
	width: '100%',
	alignItems: reverse ? 'flex-end' : 'flex-start',
	marginBottom: noMarginBottom ? 0 : theme.spacing(8),
	marginTop: marginTop ? theme.spacing(3) : 0,
	[theme.breakpoints.down('md')]: {
		marginBottom: noMarginBottom ? 0 : small ? theme.spacing(1) : theme.spacing(5),
	},
}));

interface Props extends BaseProps {
	className?: string;
	title: string;
	subtitle?: string;
	left?: boolean;
	noMarginBottom?: boolean;
	small?: boolean;
	marginTop?: boolean;
	reverse?: boolean;
}

export default (props: Props) => {
	const renderSubtitle = () => {
		if (props?.subtitle) {
			return (
				<Text variant={props?.small ? 'h4' : 'h3'} white={props?.white}>
					{props?.subtitle}
				</Text>
			);
		}
		return null;
	};

	return (
		<TitleContainer
			direction="column"
			className={props?.className}
			align={props?.left ? 'flex-start' : 'center'}
			noMarginBottom={props?.noMarginBottom}
			small={props?.small}
			marginTop={props?.marginTop}
			reverse={props?.reverse}
		>
			<Text variant={props?.small ? 'h2' : 'h1'} white={props?.white}>
				{props?.title}
			</Text>
			{renderSubtitle()}
		</TitleContainer>
	);
};
